
import { reactive, onMounted, watch } from "vue";
import { DatePicker } from 'v-calendar';


export default {
    name: "ReumTextBox",
    props: {
        modelValue: [String, Number],
        text : [String, Number],
        label: String,
        options: [Object, Array],
        readonly: {type: Boolean, default: false},
        type: {type: String, default: "text"},
        height: {type: Number, default: 150}
    },
    components: {
        DatePicker
    },
    setup(props, { emit }) {
        const comp = reactive({
            masks: {
                input: 'YYYY-MM-DD',
                data: 'YYYY-MM-DD'
            },
            select_text: "",
            select_option: [{value:'1', label:"test1"}, {value:'2', label:"test2"}],
            select_color: "#3a3a3a",
            select_color_default: "#3a3a3a",

            val : "",
            flagSelect: false,
            setSelectValue: (val) => {
                comp.val = val.value;
                comp.select_text = val.label;
                comp.flagSelect = false;
                emit("update:modelValue", comp.val);
                emit("change");
            },
            showSelect: () => {
                if(props.type=='select') {
                    comp.flagSelect = true;
                }
            },
            hideSelect: () => {
                comp.flagSelect = false;
            },
            dateToString(pdate) {
                if(typeof pdate == "string") {
                    return pdate;
                }
                return (pdate == null || pdate == ""?"":[pdate.getFullYear(),((pdate.getMonth() + 1)>9 ? '' : '0') + (pdate.getMonth() + 1),(pdate.getDate()>9 ? '' : '0') + pdate.getDate()].join('-'));
            },
            dateChange: () => {
                comp.val = comp.dateToString(comp.val);
                emit("update:modelValue", comp.val);
            },
            textChange: () => {
                emit("update:modelValue", comp.val);
            },
            onKeyEnter: (e) => {
                emit("keypress.enter");
                e.target.blur();
            }
        });
        watch(() => props.options, (nVal, oVal) => {
            if(nVal != oVal) {
                comp.select_option = props.options;
            }
        });
        watch(() => props.modelValue, (nVal, oVal) => {
            if(nVal != oVal) {
                comp.val = props.modelValue;
            }
            if(props.type=='select') {
                if(typeof props.options == "object") {
                    comp.select_option = props.options;
                    for(let i=0;i<comp.select_option.length;i++) {
                        if(comp.select_option[i].value==comp.val) {
                            comp.select_text = comp.select_option[i].label;
                            
                            if(Object.prototype.hasOwnProperty.call(comp.select_option[i], 'color')) {
                                comp.select_color = comp.select_option[i].color;
                            } else {
                                comp.select_color = comp.select_option[i].select_color_default;
                            }
                        }
                    }
                }
            }
        });
        onMounted(() => {
            comp.val = props.modelValue;
            if(props.type=='select') {
                if(typeof props.options == "object") {
                    comp.select_option = props.options;
                    for(let i=0;i<comp.select_option.length;i++) {
                        if(comp.select_option[i].value==comp.val) {
                            comp.select_text = comp.select_option[i].label;
                            if(Object.prototype.hasOwnProperty.call(comp.select_option[i], 'color')) {
                                comp.select_color = comp.select_option[i].color;
                            }
                        }
                    }
                }
            }
        });
        return { comp, props };
    }
}
