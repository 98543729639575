
import { reactive, onMounted } from 'vue'
import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";
import axios from "@/plugin/vue-axios";

import ReumTitle from '@/components/ReumLib/layout/ReumTitle.vue'
import ReumTextBox from '@/components/ReumLib/UI/ReumTextBox.vue'

import genoplan_status from '@/components/js/genoplan_status.js'

export default {
    name: 'Home',
    components: {
        ReumTitle,
        ReumTextBox
    },
    props: {
        id: {
            type: [Number, String],
            default: -1,
        },
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const toast = useToast();

        const options = {
            kit_type : [
                {value: "기본키트", label:"기본키트"}
            ],
            qc : [
                {value: "", label:"미입력"},
                {value: "PASS", label:"PASS", color:"#00F"},
                {value: "FAIL", label:"FAIL", color:"#F00"},
            ]
        }

        const comp = reactive({
            id:0,
            is_view: true,
            barcode: "",
            info: {
                serial_no: "",
                tat: ""
            },
            changeQC: () => {
                // QC
            },
            modify: () => {
                comp.is_view = false;
            },
            delete: () => {
                let params = {
                    id: comp.info.id
                }
                axios.post("/api/LibraryPrep/delete", params).then((res) => {
                    if(res.data.err == 0) {
                        router.push({path: "/LibraryPrep/list"});
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });
            },
            goView: () => {
                comp.is_view = true;
                comp.getInfo();
            },
            goList: () => {
                router.push({path: "/LibraryPrep/list"});
            },
            getBarcodeInfo: () => {
                let params = {
                    barcode: comp.barcode,
                }
                axios.post("/api/common/barcode", params).then((res) => {
                    if(res.data.err == 0) {
                        comp.info.serial_no = res.data.info.serial_no;
                        comp.info.tat = res.data.info.tat;
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });
            },
            setInfo: () => {
                let params = {
                    id:comp.id,
                    serial_no:comp.info.serial_no,
                    kit_type:comp.info.kit_type,
                    input_vol:comp.info.input_vol,
                    dw:comp.info.dw,
                    udi_dual_index_id:comp.info.udi_dual_index_id,
                    qubit:comp.info.qubit,
                    tapestation:comp.info.tapestation,
                    tapestation_bp:comp.info.tapestation_bp,
                    tapestation_file:comp.info.tapestation_file,
                    qc:comp.info.qc,
                    status:comp.info.status,
                }
                axios.post("/api/LibraryPrep/upsert", params).then((res) => {
                    if(res.data.err == 0) {
                        comp.goList();
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });

            },
            getInfo: () => {
                let params = {
                    tmr: new Date(),
                }
                axios.get("/api/LibraryPrep/info/"+ Math.round(comp.id), {params : params}).then((res) => {
                    if(res.data.err == 0) {
                        comp.info = res.data.info;
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });

            },
        });
        onMounted(() => {
            options.status = genoplan_status.LibraryPrep;
            if(route.params.id=="new") {
                comp.id = 0;
                comp.is_view = false;
                comp.info = {};
            } else {
                comp.id = route.params.id;
                if(comp.id==="") {
                    router.push({"path":"/LibraryPrep/list"});
                } else {
                    comp.getInfo();
                }
            }
        });
        return {
            comp,
            options
        };
    },
};
