
import { onMounted, reactive } from 'vue'

export default {
    name: 'Sample',
    emits: [ 'update:modelValue' ],
    setup() {
        const comp = reactive({
            data : "",
        });

        onMounted(() => {
            // Mounted
        });

        return {comp};
    }
}
